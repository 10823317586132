import React, { useState } from "react"
import { graphql, Link } from "gatsby"
// import { Helmet } from 'react-helmet'

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import CTA from '../../components/blog/cta';
import SelectPopover from '../../components/common/selectPopover';

import { readableNumber, unslugify } from '../../utils/text';
import { subredditRankingCategories } from '../../utils/reddit';

// Page for an audience
export default function Audience({data, pageContext}) {
  // page data
  const name = pageContext.name;
  const audience = data.hasura.collect_collection_by_pk;
  const subreddits = audience.collect_collectionitems.filter(ci => ci.subreddit).map(ci => ci.subreddit);

  // categories are all of the stats we have
  var sortOptions = subredditRankingCategories;
  const [sortOption, setSortOption] = useState(sortOptions[0]);

  // sort the subreddits by the current stat
  var allValues = subreddits.filter(s => s.calculated && s.calculated[sortOption['key']]).map(s => s.calculated[sortOption['key']]).sort((a,b) => (a < b) ? 1 : ((b < a) ? -1 : 0));
  allValues = sortOption.reverse ? allValues.reverse() : allValues;
  var sortedSubreddits = subreddits.map(s => {
    s['rank'] = allValues.indexOf(s.calculated[sortOption['key']]) + 1;
    s['value'] = s.calculated[sortOption['key']];
    return s;
  }).filter(s => s.rank !== 0).sort((a,b) => (a.rank < b.rank) ? -1 : ((b.rank < a.rank) ? 1 : 0));

  const rankingColors = {
    1: '#FFD700',
    2: '#C0C0C0',
    3: '#CD7F32',
  }

  // gather the top topics for these subreddits
  const countToShow = 20;
  var topicsMap = {};
  subreddits.filter(s => s.results_analysis).forEach(s => {
    Object.keys(s.results_analysis).forEach(a => {
      (s.results_analysis[a].topics || []).forEach(t => {
        topicsMap[t] ? topicsMap[t] += 1 : topicsMap[t] = 1;
      })
    });
  });
  const topics = Object.keys(topicsMap).map(t => {
    return {'label': t, 'count': topicsMap[t]}
  }).sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0));
  const topicsToShow = topics.slice(0, countToShow);
  const maxTopicsCount = topics.length ? topics[0].count : 1;

  // seo content
  const title = `Subreddits for ${audience.name}`;
  const seoComponent = <SEO
    type="article" title={title}
    description={data.description}
    // imageUrl={imageUrl}
    path={`/tools/subreddit-finder/${audience.slug}`}
    generateImage={true}
  />

  return (
    <Layout
      title={`${name} Subreddits`}
      seoComponent={seoComponent}
    >
      <div className="content-body no-header-margin">

        <div className="text-center mb-10">
          <h1>Subreddits for {name}</h1>
        </div>

        <hr className="border-gray-600"/>

        <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-6 space-y-6 md:space-y-0">
          <div className="col-span-2 space-y-6">
            
            {/* Subreddit list */}
            <div className="bg-gray-800 p-6 rounded-md">
              <div className="pb-4 mb-4 border-b border-gray-700">
                <h2 className="mt-0 font-bold text-xl">
                  {name} community rankings
                </h2>
              </div>
              <div className="flex items-center">
                <h3 className="font-bold opacity-50 text-base">{sortOption.label} for {name} subreddits</h3>
                <div className="flex flex-wrap ml-auto">
                  
                  <div className="ml-4 flex items-center">
                    <div className="text-xs opacity-50 hidden sm:block">Sort</div>
                    <div className="ml-2 flex items-center">
                      <SelectPopover
                        options={sortOptions}
                        currentOption={sortOption}
                        setCurrentOption={(s) => setSortOption(s)}
                        labelField={'label'}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4 -mx-2">
                {sortedSubreddits.map(subreddit => (
                  <div //to={`/r/${subreddit.name}/`}
                    key={subreddit.name}
                    className="p-2 rounded-md block flex items-center group"
                  >
                    <div className="w-10" style={{color: rankingColors[subreddit.rank]}}>
                      {subreddit.rank <= 3 ? (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0" />
                        </svg>
                      ) : ''}
                    </div>
                    <div className="w-12 mr-2 text-center flex items-center">
                      <span className="text-gray-600 text-2xl font-bold"># {subreddit.rank}</span>
                    </div>

                    <Link to={`/r/${subreddit.name}/`} className="bg-gray-700 rounded-md h-12 w-12 mr-4 p-2 flex-shrink-0">
                      {subreddit.data.icon ? (
                        <img src={subreddit.data.icon} className="h-full w-full rounded-md" alt={`/r/${subreddit.name}/`} />
                      ) : (
                        <svg className="h-full w-full opacity-25" enableBackground="new 0 0 24 24" height="512" fill="currentColor" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg">
                          <path d="m21.325 9.308c-.758 0-1.425.319-1.916.816-1.805-1.268-4.239-2.084-6.936-2.171l1.401-6.406 4.461 1.016c0 1.108.89 2.013 1.982 2.013 1.113 0 2.008-.929 2.008-2.038s-.889-2.038-2.007-2.038c-.779 0-1.451.477-1.786 1.129l-4.927-1.108c-.248-.067-.491.113-.557.365l-1.538 7.062c-2.676.113-5.084.928-6.895 2.197-.491-.518-1.184-.837-1.942-.837-2.812 0-3.733 3.829-1.158 5.138-.091.405-.132.837-.132 1.268 0 4.301 4.775 7.786 10.638 7.786 5.888 0 10.663-3.485 10.663-7.786 0-.431-.045-.883-.156-1.289 2.523-1.314 1.594-5.115-1.203-5.117zm-15.724 5.41c0-1.129.89-2.038 2.008-2.038 1.092 0 1.983.903 1.983 2.038 0 1.109-.89 2.013-1.983 2.013-1.113.005-2.008-.904-2.008-2.013zm10.839 4.798c-1.841 1.868-7.036 1.868-8.878 0-.203-.18-.203-.498 0-.703.177-.18.491-.18.668 0 1.406 1.463 6.07 1.488 7.537 0 .177-.18.491-.18.668 0 .207.206.207.524.005.703zm-.041-2.781c-1.092 0-1.982-.903-1.982-2.011 0-1.129.89-2.038 1.982-2.038 1.113 0 2.008.903 2.008 2.038-.005 1.103-.895 2.011-2.008 2.011z"/>
                        </svg>
                      )}
                    </Link>
                    
                    <div>
                      <h3 className="text-xl">
                        <Link to={`/r/${subreddit.name}/`} className="font-semibold border-b-2 border-dotted border-cyan-800 hover:border-cyan-500">
                          r/{subreddit.data.name}
                        </Link>
                      </h3>
                      <div className="text-gray-400 flex items-center text-sm mt-1">
                        <div className="font-semibold">{readableNumber(subreddit.data.count_users)} members</div>

                        {/*{subreddit.calculated.growth_members_monthly ? (
                          <div className={`flex text-sm items-center ml-2 ${!subreddit.calculated.growth_members_monthly ? 'text-gray-500' : subreddit.calculated.growth_members_monthly > 0 ? 'text-green-500' : 'text-red-500'}`}>
                            {subreddit.calculated.growth_members_monthly >= 0 ? (
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75L12 3m0 0l3.75 3.75M12 3v18" />
                              </svg>
                            ) : (
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 17.25L12 21m0 0l-3.75-3.75M12 21V3" />
                              </svg>
                            )}
                            <span>{subreddit.calculated.growth_members_monthly.toFixed(2) || '--'}% / mo</span>
                          </div>
                        ) : ''}*/}
                      </div>
                    </div>
                    {!sortOption.hideValues ? (
                      <div className="ml-auto text-xl text-gray-500 font-bold">
                        {/*{subreddit.value > 1000 ? readableNumber(subreddit.value) : subreddit.value > 10 ? parseInt(subreddit.value) : subreddit.value.toFixed(1)}
                        {sortOption.percent ? '%' : ''}*/}
                        {sortOption.display ? sortOption.display(subreddit) : ``}
                      </div>
                    ) : ''}   
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="col-span-1 space-y-6">

            {/* Topics */}
            <div className="bg-gray-800 p-6 rounded-md">
              <div className="pb-4 mb-4 border-b border-gray-700">
                <h2 className="mt-0 font-bold text-xl">
                  Topics Discussed
                </h2>
              </div>
              <div className="mt-4">
                {topicsToShow.map((t, i) => (
                  <div key={t.label} className="flex items-center mb-1 text-sm group">
                    <div className="mr-2 w-7 text-center font-bold text-gray-600">
                      #{i + 1}
                    </div>
                    <div className="flex-1 bg-transparent rounded-sm relative flex-shrink-0 h-7">
                      <div className="absolute bg-gray-800 bg-gray-700 rounded-sm p-1 h-full" style={{width: (100 * t.count / maxTopicsCount) + '%'}}></div>
                      <div className="absolute p-1 px-2 text-white max-w-full whitespace-nowrap overflow-ellipsis flex-shrink-0 truncate flex items-center">
                        {unslugify(t.label)}
                      </div>
                    </div>
                    {/*<div className={`ml-3 text-right ${false ? 'text-gray-300 font-semibold' : 'text-gray-500'}`}>
                      {t.count}
                    </div>*/}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-12">
        <CTA />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query AudiencePage($id: Int!) {
    hasura {
      collect_collection_by_pk(id: $id) {
        id
        name
        collect_collectionitems {
          external_id
          subreddit {
            name
            data
            calculated
            results_analysis
          }
        }
      }
    }
  }
`
