import { readableNumber } from './text';

const subredditRankingCategories = [
  {'label': 'Yearly member growth', 'key': 'per_year_members', 'display': (sub) => `+${readableNumber(sub.calculated.per_year_members)} / year`},
  {'label': 'Monthly member growth', 'key': 'per_month_members', 'display': (sub) => `+${readableNumber(sub.calculated.per_month_members)} / month`},
  {'label': 'Daily member growth', 'key': 'per_day_members', 'display': (sub) => `+${readableNumber(sub.calculated.per_day_members)} / day`},
  
  {'label': 'Daily growth (%)', 'key': 'growth_members_daily', 'percent': true, 'display': (sub) => `+${sub.calculated.growth_members_daily.toFixed(1)}% / day`},
  {'label': 'Monthly growth (%)', 'key': 'growth_members_monthly', 'percent': true, 'display': (sub) => `+${sub.calculated.growth_members_monthly.toFixed(1)}% / month`},
  {'label': 'Yearly growth (%)', 'key': 'growth_members_yearly', 'percent': true, 'display': (sub) => `+${sub.calculated.growth_members_yearly.toFixed(1)}% / year`},
  {'label': 'Posts per day', 'key': 'velocity', 'display': (sub) => `${parseInt(sub.calculated.velocity)} / day`},
  {'label': 'Upvotes per post', 'key': 'average_upvotes', 'display': (sub) => `${parseInt(sub.calculated.average_upvotes)} Upvotes`},
  {'label': 'Comments per post', 'key': 'average_comments', 'display': (sub) => `${parseInt(sub.calculated.average_comments)} Comments`},
  {'label': 'Max upvotes', 'key': 'max_comments', 'display': (sub) => `${parseInt(sub.calculated.max_comments)} Upvotes`},
  {'label': 'Largest', 'key': 'current_members', 'display': (sub) => `${readableNumber(sub.calculated.current_members)} Members`},
  {'label': 'Oldest', 'key': 'total_days', 'display': (sub) => {var d = new Date(); d.setDate(d.getDate() - sub.calculated.total_days); return d.getFullYear();}},
  {'label': 'Newest', 'key': 'total_days', 'reverse': true, 'display': (sub) => {var d = new Date(); d.setDate(d.getDate() - sub.calculated.total_days); return d.getFullYear();}},
];

const getPopularPosts = (node) => {
  if (!node || !node.results_submissions_top || !node.results_submissions_top.results) return [];
  const count_to_include = 10;
  return node.results_submissions_top.results.slice(0, count_to_include).map(p => {
    return {
      'title': p.title,
      'link': p.link,
      'score': p.score,
      'num_comments': p.num_comments,
      'type': p.type,
      'id': p.id,
    }
  });
}

export {
    getPopularPosts,
    subredditRankingCategories,
}